<template>
  <div>
    <div class="header">
      <ds-header :title="headerTitle" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :labelCol="{ style: 'width: 120px' }" labelAlign="right"
      :rules="currentRules">
      <div style="margin-top:20px">
        <div class="title">专场基础信息</div>
        <div class="content">
          <a-form-model-item label="招聘专场名称" prop="specialName">
            <a-input style="width: 800px" placeholder="请输入招聘专场名称，不超过20个字" :max-length="20"
              v-model.trim="form.specialName" />
          </a-form-model-item>
          <a-form-model-item label="所在项目" prop="projectIdList">
            <!-- <a-select style="width: 800px" mode="tags" :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择"
              v-model="form.projectIdList">
              <a-select-option v-for="item in projectList"  :key="item.projectId" :value="item.projectId">
                {{ item.projectName }}
              </a-select-option> 
            </a-select> -->
            <el-cascader  v-model="form.projectIdList" style="width: 800px"
              :options="projectList"
              :collapse-tags="true"
              :props="{emitPath:false,multiple: true,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader>
          </a-form-model-item>
          <a-form-model-item v-if="!isTenant" label="专场图片" prop="specialPictureUrl">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="true"
              :file-list="specialPictureUrlfileList" :action="IMG_API + '/oss/files'" @preview="specialPicturePreview"
              :before-upload="beforespecialPictureUpload" @change="handleSpPicChange">
              <!-- <img v-if="form.specialPictureUrl" :src="form.specialPictureUrl" alt="avatar" class="imgUpload" /> -->
              <div v-if="!form.specialPictureUrl">
                <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <a-modal :visible="specialPictureVisible" :footer="null" @cancel="specialPictureVisible = false">
              <img :src="form.specialPictureUrl" alt="avatar" class="imgUpload" style="width: 100%" />
            </a-modal>
            <span>上传小于5MB的PNG或JPG图片</span>
          </a-form-model-item>
          <a-form-model-item v-else label="专场图片">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="true"
              :file-list="specialPictureUrlfileList" :action="IMG_API + '/oss/files'" @preview="specialPicturePreview"
              :before-upload="beforespecialPictureUpload" @change="handleSpPicChange">
              <!-- <img v-if="form.specialPictureUrl" :src="form.specialPictureUrl" alt="avatar" class="imgUpload" /> -->
              <div v-if="!form.specialPictureUrl">
                <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <a-modal :visible="specialPictureVisible" :footer="null" @cancel="specialPictureVisible = false">
              <img :src="form.specialPictureUrl" alt="avatar" class="imgUpload" style="width: 100%" />
            </a-modal>
            <span>上传小于5MB的PNG或JPG图片</span>
          </a-form-model-item>
          <a-form-model-item label="专场介绍" prop="specialIntroduce">
            <div id="spIntrEditer" style="width: 800px;"></div>
          </a-form-model-item>
          <a-form-model-item label="专场视频" prop="specialVideoUrl">
            <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
              :action="IMG_API + '/oss/files'" :before-upload="beforeSpVidUpload" @change="handleSpVidChange">
              <video v-if="form.specialVideoUrl"
                :poster="`${form.specialVideoUrl}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`"
                :src="form.specialVideoUrl" controls style="width: 400px;">
              </video>
              <div v-else>
                <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
            <div v-if="form.specialVideoUrl">
              <a-button style="background: rgb(255, 0, 0);border: none;" type="primary" danger
                @click="form.specialVideoUrl = undefined">删除视频</a-button>
            </div>
            <span>上传小于100MB的MP4视频</span>
          </a-form-model-item>
          <a-form-model-item label="专场背景" prop="specialColorNumber">
            <a-input style="width: 250px" placeholder="请输入专场背景色Hex值" :max-length="6" addon-before="#"
              v-model.trim="form.specialColorNumber">
              <a-tooltip slot="suffix" v-if="form.specialColorNumber">
                <a-icon type="check-circle" theme="filled" :style="`color: #${form.specialColorNumber}`" />
              </a-tooltip>
            </a-input>
          </a-form-model-item>
        </div>
      </div>
      <div style="margin-top:20px">
        <div class="title">专场模块信息</div>
        <div class="content">
          <a-form-model-item prop="specialModuleInfoList">
            <div v-for="(item, index) in form.specialModuleInfoList">
              <a-form-model-item :label="`专场模块${index + 1}：`" prop="`spmod${index+1}`"
                style="text-align: right; width: 920px;">
                <a-popconfirm :getPopupContainer="(triggerNode) => triggerNode.parentNode" okText="确定" cancelText="取消" style="width: 300px;" v-if="$route.query.id" title="您将删除此模块及此模块绑定的所有职位，确定要删除吗？" ok-text="Yes" cancel-text="No" @confirm="confirm(index)">
                  <a-button type="link">
                    <a-icon type="minus-circle" style="color: #FF0000; font-size: 14px;" />
                  </a-button>
                </a-popconfirm>
                <a-button v-else type="link" @click="delSpMod(index)">
                    <a-icon type="minus-circle" style="color: #FF0000; font-size: 14px;" />
                  </a-button>
              </a-form-model-item>
              <a-form-model-item label="专场模块名称" :prop="`moduleName${index}`">
                <a-input style="width: 800px" :placeholder="`请输入专场模块名称，不超过10个字`" :max-length="10"
                  v-model.trim="item.moduleName" @change="handleSpModNameChange($event, index, form)"
                  @blur="handleSpModNameChange($event, index, form)" />
              </a-form-model-item>
              <a-form-model-item label="专场模块图片" :prop="`modulePictureUrl${index}`">
                <a-upload list-type="picture-card" class="avatar-uploader" :file-list="fileList[index]"
                  :show-upload-list="true" :action="IMG_API + '/oss/files'" :before-upload="beforeSpPicUpload"
                  @preview="handlePreview" @change="handleSpModPicChange($event, index, form)">
                  <!-- <img v-if="item.modulePictureUrl" :src="item.modulePictureUrl" alt="avatar" class="imgUpload" />  -->
                  <div v-if="!item.modulePictureUrl">
                    <a-icon :type="spModPicLoadings[index] ? 'loading' : 'plus'" />
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="item.modulePictureUrl" />
                </a-modal>
                <span>上传小于1MB的PNG或JPG图片</span>
              </a-form-model-item>
            </div>
            <div style="text-align: center; width: 920px;">
              <a-button type="dashed" @click="addSpMod">
                <a-icon type="plus" /><span>新增专场模块</span>
              </a-button>
            </div>
          </a-form-model-item>
        </div>
      </div>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
        <a-button type="primary" @click="submit" style="margin-right: 20px"> 确定 </a-button>
        <a-button type="info" @click="goBack"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import * as api from "../../../../api/socialRecruitingServicesManagement";
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
export default {
  name: "specialAdd",
  data() {
    const validateColorNumber = (rule, value, callback) => {
      console.log(rule, value);
      if (/^[A-Fa-f0-9]{1,6}$/.test(value)) {
        callback()
      } else {
        callback(new Error('请正确输入背景色Hex值'))
      }
    }
    return {
      previewVisible: false,
      specialPictureVisible: false,
      // 标题
      headerTitle: "",
      form: {
        // 专场ID
        specialId: undefined,
        // 专场名称
        specialName: undefined,
        // 专场图片URL
        specialPictureUrl: undefined,
        // 专场介绍
        specialIntroduce: undefined,
        // 专场视频URL
        specialVideoUrl: undefined,
        // 专场背景色号
        specialColorNumber: undefined,
        // 模块数据集合
        specialModuleInfoList: [],
        projectIdList: [],// 专场所属项目集合
      },
      rules: {
        specialName: [{ required: true, message: "请输入招聘专场名称，不超过20个字", trigger: "blur" }],
        projectIdList: [{ required: true, message: "请选择所在项目", trigger: "change" }],
        specialPictureUrl: [{ required: true, message: "请上传专场图片", trigger: "change" }],
        specialColorNumber: [{ required: true, message: "请输入背景色Hex值", trigger: "change" }, { validator: validateColorNumber, trigger: "blur", message: "请正确输入背景色Hex值", }],
      },
      IMG_API: IMG_API,
      // 专场图片loading
      spPicLoading: false,
      // 专场视频loading
      spVidLoading: false,
      // 专场模块图片loading
      spModPicLoadings: [],
      // 专场介绍编辑器
      spIntrEditer: null,
      // 计数器
      counter: 0,
      // 模块图片预览
      fileList: [],
      // 专场图片
      specialPictureUrlfileList: [],
      // 所在项目
      projectList: [],
      isTenant:false, // false 非顺义项目 true 是
    };
  },
  created(){
    // 是否是顺义
     api.isTenant().then(res=>{
      this.isTenant = res.data
      console.log(res,'=======>');
     })
  },
  computed: {
    currentRules: function () {
      if (this.counter >= 0) {
        return this.rules;
      }
      return this.rules;
    },
  },
  watch: {
    // 'form.projectIdList':{
    //   handler(val) {
    //     this.$refs.ruleForm.validateField('projectIdList',async (valid) => {
    //    console.log('========>','冲洗校验');
    //   })
    //   },
    //   deep:true,
    // }
  },
  mounted() {
    this.getproject()
    if (this.$route.query.id) {
      this.headerTitle = "编辑招聘专场";
      this.getDetail();
    } else {
      this.headerTitle = "新增招聘专场";
    }
    const _this = this
    const editor = new wangEditor(`#spIntrEditer`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.specialIntroduce = newHtml
    }
    // 隐藏全屏
    editor.config.showFullScreen = false
    editor.config.menus = [
      'fontName',
      'fontSize',
      'foreColor',
      'backColor',
      'underline',
      'italic',
      'bold',
      'justify',
      'splitLine',
      'undo',
      'redo',
      // 'list',
      // 'table',
      // 'image',
      // 'video',
    ]
    editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgShowBase64 = false
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = 'file'
    editor.config.uploadVideoName = 'file'
    editor.config.uploadVideoTimeout = 60000
    editor.config.uploadImgTimeout = 60000
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function (xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri + '" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do('insertHTML', videoHTML);
      }
    }
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function (xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case 'success':
          _this.$message.success(s)
          break
        case 'info':
          _this.$message.info(s)
          break
        case 'warning':
          _this.$message.warning(s)
          break
        case 'error':
          _this.$message.error(s)
          break
        default:
          _this.$message.info(s)
          break
      }
    }
    editor.config.zIndex = 1;
    editor.config.placeholder = '请输入专场介绍'
    // 创建编辑器
    editor.create()
    this.spIntrEditer = editor;
  },
  methods: {
    confirm(e) {
      console.log(e);
      this.delSpMod(e)
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewVisible = true;
    },
    async specialPicturePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.specialPictureVisible = true;
    },
    // 获取详情数据
    async getDetail() {
      try {
        const specialId = this.$route.query.id;
        const res = await api.specialDetail(specialId);
        if (res.code === "200") {
          this.form = res.data;
          this.specialPictureUrlfileList = res.data.specialPictureUrl? [{
            uid: '-1',
            name: 'xx',
            status: 'done',
            url: res.data.specialPictureUrl
          }]:[];
          // 重绘专场模块动态规则
          for (let spModIdx = 0; spModIdx < res.data.specialModuleInfoList.length; spModIdx++) {
            this.fileList[spModIdx] = [{
              uid: res.data.specialModuleInfoList[spModIdx].moduleId + spModIdx,
              name: 'xx',
              status: 'done',
              url: res.data.specialModuleInfoList[spModIdx].modulePictureUrl
            }]
            this.rules['moduleName' + spModIdx] = [{ required: true, message: "请输入专场模块名称，不超过10个字", trigger: "blur" }];
            this.rules['modulePictureUrl' + spModIdx] = [{ required: true, message: "请上传专场模块图片", trigger: "blur" }];
            this.$set(this.form, "moduleName" + spModIdx, res.data.specialModuleInfoList[spModIdx].moduleName);
            this.$set(this.form, "modulePictureUrl" + spModIdx, res.data.specialModuleInfoList[spModIdx].modulePictureUrl);
          }
          this.spIntrEditer.txt.html(this.form.specialIntroduce)
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确定
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
        if(this.isTenant && !this.form.specialPictureUrl && !this.form.specialIntroduce){
          this.$message.error('专场图片和专场介绍任选其一填写')
          return
        }

          let obj = {
            specialColorNumber: this.form.specialColorNumber,
            specialId: this.form.specialId,
            specialIntroduce: this.form.specialIntroduce,
            specialModuleInfoList: this.form.specialModuleInfoList,
            specialName: this.form.specialName,
            specialPictureUrl: this.form.specialPictureUrl,
            specialVideoUrl: this.form.specialVideoUrl,
            projectIdList: this.form.projectIdList  
          };
          if (this.$route.query.id) {
            const res = await api.editSpecial(obj);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.$router.push({
                path: "/socialRecruitingServicesManagement/special",
              });
            } else {
              this.$message.error(res.msg);
            }
          } else {
            try {
              const res = await api.addSpecial(obj);
              if (res.code === "200") {
                this.$message.success("添加成功");
                this.$router.push({
                  path: "/socialRecruitingServicesManagement/special",
                });
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) { }
          }
        } else {
        }
      });
    },
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 专题图片上传
    handleSpPicChange(info) {
      this.specialPictureUrlfileList = info.fileList
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "removed") {
        this.form.specialPictureUrl = '';
        this.specialPictureUrlfileList = []
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.specialPictureUrl = info.file.response.redirect_uri;
        this.specialPictureUrlfileList = [{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.redirect_uri
        }]
      }
    },
    // 专题图片限制
    beforeSpPicUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (!isLt1M) {
        this.$message.error("上传图片最大1M");
        return Promise.reject();
      }
      return isJpgOrPng && isLt1M;
    },
    beforespecialPictureUpload(file) {
      // 校验格式
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg、png格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isLt5M) {
        this.$message.error("上传图片最大5M");
        return Promise.reject();
      }
      return isJpgOrPng && isLt5M;
    },
    // 专题模块图片上传
    handleSpModPicChange(info, idx, refForm) {
      console.log(info.fileList, idx, refForm);
      this.$set(this.fileList, idx, info.fileList)
      console.log(this.fileList[idx]);
      if (info.file.status === "uploading") {
        this.spModPicLoadings[idx] = true;
        return;
      }
      if (info.file.status === "removed") {
        this.form.specialModuleInfoList[idx].modulePictureUrl = '';
        this.$delete(refForm, "modulePictureUrl" + idx);
        this.fileList[idx] = []
        return;
      }
      if (info.file.status === "done") {
        this.spModPicLoadings[idx] = false;
        this.form.specialModuleInfoList[idx].modulePictureUrl = info.file.response.redirect_uri;
        this.$set(refForm, "modulePictureUrl" + idx, info.file.response.redirect_uri);
        this.fileList[idx] = [{
          uid: info.file.uid,
          name: info.file.name,
          status: 'done',
          url: info.file.response.redirect_uri
        }]
      }
    },
    // 专场模块名称变更赋值
    handleSpModNameChange(info, idx, refForm) {
      this.$set(refForm, "moduleName" + idx, refForm.specialModuleInfoList[idx].moduleName);
    },
    // 专场视频限制
    beforeSpVidUpload(file) {
      // 校验格式
      const isMp4 = file.type === "video/mp4";
      if (!isMp4) {
        this.$message.error("请上传MP4格式");
        return Promise.reject();
      }
      // 校验大小
      const isLt500M = file.size / 1024 / 1024 <= 100;
      if (!isLt500M) {
        this.$message.error("上传视频最大100M");
        return Promise.reject();
      }
      return isMp4 && isLt500M;
    },
    // 专场视频上传
    handleSpVidChange(info) {
      console.log(info);
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.loading = false;
        this.form.specialVideoUrl = info.file.response.redirect_uri;
      }
    },
    // 添加专场模块
    addSpMod() {
      this.form.specialModuleInfoList.push({
        moduleId: "",
        moduleName: "",
        modulePictureUrl: ""
      });
      this.fileList.push([])
      this.spModPicLoadings.push(false);
      let spModIdx = this.form.specialModuleInfoList.length - 1;
      this.rules['moduleName' + spModIdx] = [{ required: true, message: "请输入专场模块名称，不超过10个字", trigger: "blur" }];
      this.rules['modulePictureUrl' + spModIdx] = [{ required: true, message: "请上传专场模块图片", trigger: "blur" }];
      this.$set(this.form, "moduleName" + spModIdx, "");
      this.$set(this.form, "modulePictureUrl" + spModIdx, "");
      this.counter++;
    },
    // 删除专场模块
    delSpMod(idx) {
      this.form.specialModuleInfoList.splice(idx, 1);
      this.spModPicLoadings.splice(idx, 1);
    },
    // 获取项目
   async getproject() {
    let res = await  api.getShunyiAllProject({check_type:true})
    this.projectList = res.data
    }
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.content {
  margin-top: 16px;
}

::v-deep .ant-form-item {
  display: flex;
}

::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}

.tips {
  color: #d9d9d9;
  position: absolute;
  bottom: -40px;
  right: 0;
}

.imgUpload {
  width: 102px;
  // height: 102px;
}

.ant-modal-mask {
  opacity: 0.3;
}
</style>
